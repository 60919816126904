import React, { useState, useEffect } from "react";
import "./index.css";

import Measure from "./components/measure";
import Container from "./components/container";
import Search from "./components/features/search";
import Panels from "./components/features/panels";
import Info from "./components/features/info";
import SignupBtn from "./components/signup_btn";
import SignupForm from "./components/signup_form";

import logo from "./assets/img/logo.png";
import preview_1 from "./assets/img/preview_1.png";
import map from "./assets/img/map.png";
import blur_purple from "./assets/img/blur_purple.png";
import earth from "./assets/img/earth.png";

function App() {
  const [showSignup, setShowSignup] = useState(false);

  useEffect(() => {
    const containers = document.querySelectorAll(".arrow-container");

    function checkTextWrap() {
      containers.forEach((container) => {
        const span = container.querySelector("span");
        if (container.offsetHeight > span.offsetHeight) {
          container.classList.add("wrapped");
        } else {
          container.classList.remove("wrapped");
        }
      });
    }

    window.addEventListener("resize", checkTextWrap);
    checkTextWrap();

    return () => window.removeEventListener("resize", checkTextWrap);
  }, []);

  useEffect(() => {
    console.log("showSignup", showSignup);
  }, [showSignup]);

  return (
    <div className="relative h-screen w-screen overflow-x-hidden bg-[#101010]">
      {/* <Measure /> */}
      {showSignup && <SignupForm setShowSignup={setShowSignup} />}

      <Container>
        {/* Logo */}
        <div className="relative z-40 w-full py-12 pl-6">
          <img src={logo} alt="Logo" className="w-56 lg:w-48" />
        </div>

        {/* Title */}
        <h1 className="relative z-20 mt-40 flex flex-col items-center justify-center text-center font-extrabold text-white">
          <span>Essential data</span>
          <span>
            for{" "}
            <span className="block bg-clip-text pb-4 text-[#952bb3] 2xs:inline">
              Digital Nomads
            </span>
          </span>
        </h1>

        {/* Subtitle */}
        <h5 className="relative z-20 mt-[2rem] text-center text-gray-300">
          <span className="block">
            Comprehensive city insights in one place,
          </span>
          <span className="block sm:inline">saving you hours of research </span>
          <span>and helping you plan with ease</span>
        </h5>

        {/* Signup Button */}
        <SignupBtn setShowSignup={setShowSignup} />

        {/* App Preview with Blur */}
        {/* Here's the tricks involved here:
            1. We have a parent div with relative position and a child div with absolute position. This essentially ties the absolute
              div to the parent div, instead of it being absolutely positioned on screen. The rule goes an absolute div will get positioned
              according to the nearest parent with relative position. If there is no parent with relative position, it will be positioned
              according to the body.
            2. The parent has overflow-visible to allow the blur to be positioned outside the boundary of the parent and still be visible.
         */}
        <div className="relative flex w-full items-center justify-center overflow-visible px-8 pt-32 md:px-16">
          <img src={preview_1} alt="AppPreview1" className="relative z-20" />
          <div className="absolute left-1/2 top-0 z-10 w-[700px] -translate-x-1/2 -translate-y-1/4 transform 2xs:w-[900px] xs:w-[1100px] sm:w-[1200px] md:w-[1500px] lg:w-[1920px]">
            <img src={blur_purple} alt="blur" className="w-full" />
          </div>
        </div>

        {/* All Over the Globe */}
        <div className="relative z-20 mb-[28rem] mt-[20rem] flex justify-center 2xs:mb-[20rem] sm:mb-[28rem] sm:mt-[28rem]">
          <h2 className="relative z-20 flex flex-col items-center justify-center text-center text-white">
            <span>Trusted by remote workers</span>
            <div className="flex flex-row">
              <span className="bg-[#9E00FF] px-2">all over the globe</span>
              <div className="flash -ml-1 h-full w-[0.4rem] bg-white" />
            </div>
          </h2>
          <div className="absolute left-1/2 z-10 w-[90vw] min-w-[380px] max-w-[1200px] -translate-x-1/2 -translate-y-1/4 transform">
            <img src={map} alt="map" className="mx-auto w-full opacity-10 " />
          </div>
        </div>

        {/* Features */}
        <Search />
        <div className="mt-56 md:mt-64 lg:mt-[28rem] xl:mt-64">
          <Panels />
        </div>
        <div className="mt-56 md:mt-64 lg:mt-[28rem] xl:mt-64">
          <Info />
        </div>

        {/* Call to action */}
        <div className="relative mt-64 flex flex-col">
          <h1 className="relative z-20 bg-gradient-to-r from-[#d985ee] to-[#541a64] bg-clip-text text-center text-[5rem] text-transparent">
            Unlock the World
          </h1>
          <h3 className="relative z-20 mt-2 text-center text-[2.8rem] font-bold text-white">
            Start your journey now
          </h3>

          <SignupBtn setShowSignup={setShowSignup} />

          <div className="relative mt-48 flex flex-row">
            <div className="absolute left-1/2 top-0 w-[700px] -translate-x-1/2 -translate-y-[10rem] transform xs:w-[1000px] sm:w-[1000px] md:w-[1300px] lg:w-[1700px]">
              <img src={earth} alt="earth" className="h-full w-full" />
            </div>
          </div>
        </div>
      </Container>

      {/* Footer */}
      <div className="relative z-10 mt-36 flex w-screen flex-col items-center justify-center border-t border-gray-600 bg-[#101010] xs:mt-[10rem] sm:mt-[7rem] md:mt-[8rem] lg:mt-[16rem]">
        <div className="mb-20 mt-20">
          <div className="mb-6 flex justify-center">
            <img src={logo} alt="Logo" className="w-56" />
          </div>
          <span className="text-[1rem] text-[#888888]">
            ©2024. <b>DriftPlanner</b>. All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
