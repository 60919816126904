export default function Feature({ icon, title, description }) {
  return (
    <div className="mb-8 flex flex-1 flex-col items-start">
      <div className="mb-4 flex h-16 w-full flex-row items-center sm:mb-0 lg:mb-0">
        <img
          src={icon}
          alt="icon"
          className="aspect-square h-[28px] object-scale-down sm:h-[32px] md:h-[40px]"
        />
        <h5 className="ml-4 font-bold text-white [font-size:max(14pt,1.2rem)] lg:[font-size:max(18pt,1.4rem)]">
          {title}
        </h5>
      </div>
      <h6 className="ml-2">{description}</h6>
    </div>
  );
}
