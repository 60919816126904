import pointer from "../assets/img/pointer.png";

export default function SignupBtn({ setShowSignup }) {
  return (
    <div
      className="relative z-20 mt-12 flex justify-center"
      onClick={() => setShowSignup(true)}
    >
      <button className="flex h-16 w-96 flex-row items-center justify-center rounded-sm bg-gray-200 px-[3rem] py-[0.5rem] text-[1.5rem] font-extrabold text-black hover:bg-gray-50 2xs:h-14 2xs:text-[1.4rem] xs:text-[1.2rem] sm:w-80 sm:text-[1rem] lg:px-[1rem]">
        Launch DriftPlanner
        <img src={pointer} alt="pointer" className="ml-2 aspect-square h-4" />
      </button>
    </div>
  );
}
