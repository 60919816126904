import React from "react";
import "../../index.css";

import Feature from "../feature";

import preview_4 from "../../assets/img/preview_4.png";
import blur_green from "../../assets/img/blur_green.png";
import lightning from "../../assets/img/lightning.png";
import linehook from "../../assets/img/linehook.png";
import house from "../../assets/img/house.png";
import eye from "../../assets/img/eye.png";
import key from "../../assets/img/key.png";
import circle from "../../assets/img/circle.png";

export default function Info() {
  return (
    <div className="relative ml-8 flex flex-row lg:ml-0 xl:mx-20">
      <div className="2xs:w-[900px] absolute left-1/2 top-0 z-0 w-[700px] -translate-x-1/2 -translate-y-[10rem] transform xs:w-[1000px] sm:w-[1000px] md:w-[1300px] lg:w-[1700px]">
        <img src={blur_green} alt="blur" className="relative z-0 w-full" />
      </div>

      <div className="relative flex w-16 flex-col">
        <img src={lightning} alt="Magnify" className="icon z-20" />
        <img
          src={linehook}
          alt="LineHook"
          className="2xs:h-[59%] w-w24 absolute left-1/2 z-10 h-[50%] xs:h-[63%] sm:h-[68%] md:h-[85%] lg:h-[90%] xl:h-[91%] 2xl:h-[90%]"
        />
      </div>

      <div className="relative z-10 inline-flex w-full flex-col px-8 pt-16">
        <h2 className="relative text-white">
          <span>All the </span>
          <span className="relative text-green-300">information</span>
          <img
            src={circle}
            alt="circle"
            className="absolute -top-[0.8rem] left-[8.4rem] w-[20rem]"
          />{" "}
          <span> you need</span>
        </h2>

        <h4 className="text-white">
          Always have the important information at hand, without having to
          search multiple sources.
        </h4>

        <img src={preview_4} className="w-full pt-8" />

        <div className="mb-8 ml-4 mr-16 mt-12 flex flex-col space-x-0 md:mb-0 md:flex-row md:space-x-20">
          <Feature
            icon={key}
            title="Safety is Key"
            description="Access vital information about the safety of each city, including crime rates, safe neighborhoods and emergency contact numbers."
          />

          <Feature
            icon={eye}
            title="Cultural Insight"
            description="Dive into the local culture before you arrive. Learn about customs,  traditions, and social norms to ensure you blend in seamlessly."
          />

          <Feature
            icon={house}
            title="Cost of Living"
            description="Get cost of living breakdowns, including accommodation, food and transportation. Compare prices across different cities to find the perfect  balance between affordability and quality of life."
          />
        </div>
      </div>
    </div>
  );
}
