import React from "react";
import "../../index.css";

import Feature from "../feature";

import preview_2 from "../../assets/img/preview_2.png";
import blur_yellow from "../../assets/img/blur_yellow.png";
import arrow from "../../assets/img/arrow.png";
import magnify from "../../assets/img/magnify.png";
import linehook from "../../assets/img/linehook.png";
import passport from "../../assets/img/passport.png";
import plane from "../../assets/img/plane.png";
import coin from "../../assets/img/coin.png";

export default function Search() {
  return (
    <div className="relative ml-8 flex flex-row lg:ml-0 xl:mx-20">
      <div className="2xs:w-[900px] absolute left-1/2 top-0 z-10 w-[700px] -translate-x-1/2 -translate-y-[6rem] transform xs:w-[900px] sm:w-[1000px] md:w-[1300px] md:-translate-y-[16rem] lg:w-[1700px]">
        <img src={blur_yellow} alt="blur" className="w-full" />
      </div>

      <div className="relative flex w-16 flex-col">
        <img src={magnify} alt="Magnify" className="icon z-20" />
        <img
          src={linehook}
          alt="LineHook"
          className="2xs:h-[59%] w-w24 absolute left-1/2 z-10 h-[50%] xs:h-[65%] sm:h-[69%] md:h-[85%] lg:h-[92%] xl:h-[91%] 2xl:h-[92%]"
        />
      </div>

      <div className="relative z-10 inline-flex w-full flex-col px-8 pt-16">
        <h2 className="arrow-container relative text-white">
          <span>Super easy </span>
          <span className="text-yellow-300">
            destination
            <img
              src={arrow}
              alt="arrow"
              className="absolute left-[24rem] top-[-3rem] w-[14rem]"
            />{" "}
          </span>
          <span> search</span>
        </h2>

        <h4 className="text-white">
          Use powerful search filters to find the destinations that work best
          for you.
        </h4>

        <img src={preview_2} className="w-full pt-16" />

        <div className="mb-8 ml-4 mr-16 mt-12 flex flex-col space-x-0 md:mb-0 md:flex-row md:space-x-20">
          <Feature
            icon={passport}
            title="Visa Information"
            description="Personalized visa info for your passport. Discover where you can travel visa-free and the length of stay, plus visa requirements for other countries."
          />

          <Feature
            icon={coin}
            title="Cost Analysis"
            description="Filter destinations by cost to find affordable travel options. Easily see which countries are cheap, moderately expensive, or pricey to help you plan your trip."
          />

          <Feature
            icon={plane}
            title="Flight Layovers"
            description="Find destinations based on flight options. See countries you can reach with one, two, or more flights."
          />
        </div>
      </div>
    </div>
  );
}
