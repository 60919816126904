import React from "react";
import "../../index.css";

import Feature from "../feature";

import preview_3 from "../../assets/img/preview_3.png";
import blur_pink from "../../assets/img/blur_pink.png";
import bulb from "../../assets/img/bulb.png";
import linehook from "../../assets/img/linehook.png";
import ai from "../../assets/img/ai.png";
import tree from "../../assets/img/tree.png";
import compass from "../../assets/img/compass.png";
import squiggle from "../../assets/img/squiggle.png";

export default function Panels() {
  return (
    <div className="relative ml-8 flex flex-row lg:ml-0 xl:mx-20">
      <div className="2xs:w-[900px] absolute left-1/2 top-0 z-0 w-[700px] -translate-x-1/2 -translate-y-[10rem] transform xs:w-[1000px] sm:w-[1000px] md:w-[1300px] lg:w-[1800px]">
        <img src={blur_pink} alt="blur" className="w-full" />
      </div>

      <div className="relative flex w-16 flex-col">
        <img src={bulb} alt="Magnify" className="icon z-20" />
        <img
          src={linehook}
          alt="LineHook"
          className="2xs:h-[59%] w-w24 absolute left-1/2 z-10 h-[50%] xs:h-[65%] sm:h-[68%] md:h-[85%] lg:h-[90%] xl:h-[91%] 2xl:h-[90%]"
        />
      </div>

      <div className="relative z-10 inline-flex w-full flex-col px-8 pt-16">
        <h2 className="relative text-white">
          <span>Netflix style location </span>
          <span className="relative text-pink-400">
            suggestions
            <img
              src={squiggle}
              alt="squiggle"
              className="absolute -left-[0.2rem] top-[3.4rem] w-[20rem]"
            />{" "}
          </span>
        </h2>

        <h4 className="text-white">
          Intelligent categories help you find ideal beach vibes, hiking
          adventures or futuristic cityscapes.
        </h4>

        <img src={preview_3} className="w-full pt-16" />

        <div className="mb-8 ml-4 mr-16 mt-12 flex flex-col space-x-0 md:mb-0 md:flex-row md:space-x-20">
          <Feature
            icon={ai}
            title="Recommendations"
            description="Not sure where to go? Let us choose for you! Based on your current location, ease of travel, and safety, we provide tailored destination recommendations."
          />

          <Feature
            icon={tree}
            title="Follow Interests"
            description="Explore locations by categories, allowing you to find destinations that meet your needs. Whether it’s neon soaked cities, zen Buddhist areas, or just sea and sand, we’ve got you covered."
          />

          <Feature
            icon={compass}
            title="Route Explorer"
            description="Need to be back home for December? Enter your desired destination and travel date and we’ll nudge you towards interesting destinations whilst drifting you towards where you need to be."
          />
        </div>
      </div>
    </div>
  );
}
