import React, { useState, useEffect } from "react";

import logo from "../assets/img/logo.png";

export default function SignupForm({ setShowSignup }) {
  const [signupComplete, setSignupComplete] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState("");

  useEffect(() => {
    setValidEmail(isValidEmail(email));
  }, [email]);

  return (
    <div
      className="fixed z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50"
      onClick={() => setShowSignup(false)}
    >
      <div
        className="absolute flex w-[40rem] flex-col rounded-md bg-[#2B2C2E] p-8 text-white"
        onClick={(e) => e.stopPropagation()}
      >
        {signupComplete
          ? SignupComplete()
          : SignupContent(email, setEmail, validEmail, setSignupComplete)}
      </div>
    </div>
  );
}

function SignupContent(email, setEmail, validEmail, setSignupComplete) {
  return (
    <div className="flex flex-col">
      <div className="flex justify-center">
        <img
          src={logo}
          alt="logo"
          className="mt-6 w-[24rem] xs:mt-4 xs:w-[20rem] sm:w-[18rem] md:mt-4 md:w-[16rem]"
        />
      </div>
      <h5 className="mt-16 text-center text-[15pt] font-extrabold xs:text-[1.6rem]">
        We're Launching Soon!
      </h5>
      <span className="text-center text-[9pt] xs:text-[1rem]">
        Let us know your email and we’ll notify you as soon as we go live.
      </span>
      <input
        type="email"
        placeholder="your@email.com"
        className="mt-8 h-14 w-full rounded-md bg-[#2B2C2E] text-[9pt] xs:h-10 xs:text-[1rem]"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      {validEmail === false ? (
        <button className="mt-4 h-14 w-full rounded-md bg-[#414246] text-[9pt] text-[#aeafb9] xs:h-10 xs:text-[1rem]">
          Join the club
        </button>
      ) : (
        <button
          className="mt-4 h-14 w-full rounded-md bg-[#8733BA] text-[9pt] xs:h-10 xs:text-[1rem]"
          onClick={() => {
            setSignupComplete(true);
          }}
        >
          Join the club
        </button>
      )}
    </div>
  );
}

function SignupComplete() {
  return (
    <div className="flex flex-col">
      <div className="flex justify-center">
        <img
          src={logo}
          alt="logo"
          className="mt-6 w-[24rem] xs:mt-4 xs:w-[20rem] sm:w-[18rem] md:mt-4 md:w-[16rem]"
        />
      </div>

      <h5 className="mt-16 text-center text-[15pt] font-extrabold xs:text-[1.6rem]">
        Thanks 👍
      </h5>
      <span className="mb-10 text-center text-[9pt] xs:text-[1rem]">
        We'll keep you posted!
      </span>
    </div>
  );
}

function isValidEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}
